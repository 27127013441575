import React, { useState } from "react";
import "./HomePage.css";
import { useLocation, useParams } from "react-router-dom";
import img_optolinqName from "../../Assets/OPTO LINQ.png";
import optolinqlogo from "../../Assets/OptoIcon.ico";
import pillp from "../../Assets/image.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Dropdown, Tooltip } from "bootstrap/dist/js/bootstrap.bundle.min";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Homepageroutes } from "../Routes/OptoRoute";

import {
  faBars,
  faStethoscope,
  faLayerGroup,
  faBoxOpen,
  faMoneyCheckDollar,
  faCircleChevronDown,
  faBell,
  faArrowRightFromBracket,
  faHome,
  faCopyright,
  faHeadset,
  faUserCircle,
  faClose,
  faPaste,
  faGears,
  faCross,
} from "@fortawesome/free-solid-svg-icons";

import { Link, Route, Routes, Outlet } from "react-router-dom";

const HomePage = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const breadcrumbNames = Homepageroutes.reduce((acc, route) => {
    acc[route.path] = route.name.split(/(?<!^)(?=[A-Z])/).join(" ");
    return acc;
  }, {});

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  console.log(location.pathname);


  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    const dropdownElements = document.querySelectorAll(
      '[data-bs-toggle="dropdown"]'
    );

    dropdownElements.forEach((dropdownElement) => {
      new Dropdown(dropdownElement);
    });

    const tooltipTriggerEl = document.querySelector(
      '[data-bs-toggle-tooltip="tooltip"]'
    );
    if (tooltipTriggerEl) {
      new Tooltip(tooltipTriggerEl, {
        trigger: "hover",
      });
    }

    return () => {};
  }, [activeLink, isCollapsed]);

  const isActive = (path) => {
    return location.pathname.includes(path) ? "isActive" : "";
  };

  const isDropdownActive = (paths) => {
    return paths.some((path) => location.pathname.includes(path))
      ? "isActive"
      : "";
  };

  return (
    <>
      <div className="d-flex flex-row ">
        {/* Sidebar*/}
        <div
          className={`sidebar d-flex flex-column justify-content-between fixed-top bg-white vh-100 ${
            isCollapsed ? "collapsed" : ""
          }`}
          style={{ minWidth: isCollapsed ? "80px" : "250px" }}
        >
          <div className="p-3 sidebar-top-content" style={{ height: "50px" }}>
            {isCollapsed ? (
              <img
                src={optolinqlogo}
                className="logo2"
                alt="logo2"
                style={{ width: "auto", height: "40px" }}
                data-bs-toggle-tooltip="tooltip"
                title="OptoLinq"
              />
            ) : (
              <img
                src={img_optolinqName}
                className="logo"
                alt="logo"
                style={{ width: "100%", height: "auto" }}
                data-bs-toggle-tooltip="tooltip"
                title="OptoLinq Full Name"
              />
            )}

            <ul className="nav flex-column text-center">
              <li
                className={`nav-item  ${
                  isCollapsed ? "mx-auto" : ""
                } ${isActive("/HomePage/Dashboard")}`}
              >
                <Link
                  to={"/HomePage/Dashboard"}
                  className={`nav-link text-black p-2 d-flex align-items-center sidebarLink`}
                  onClick={() => setIsCollapsed(true)}
                  data-bs-toggle-tooltip="tooltip"
                  title="Dashboard"
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faHome}
                    style={{ color: "#717171", width: "25px", height: "auto" }}
                  />
                  <span>Dashboard</span>
                </Link>
              </li>

              <li
                className={`nav-item ${
                  isCollapsed ? "mx-auto" : ""
                } ${isDropdownActive([
                  "/Homepage/ClientSearch",
                  "/Homepage/PatientSearch",
                  "/Homepage/SearchSuppliers",
                  "/Homepage/SearchStaff",
                ])}`}
              >
                <div
                  className={`sideNavDropDown ${isCollapsed ? "drop-end" : ""}`}
                >
                  <button
                    type="button"
                    className="sideNavDropDown text-start"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-toggle-tooltip="tooltip"
                    title="Administration"
                  >
                    <div className="nav-link p-2 text-black d-flex align-items-center justify-content-between">
                      <div className="nav_dropdown_left_items d-flex align-items-center">
                        <FontAwesomeIcon
                          className="sidebaricon"
                          icon={faLayerGroup}
                          style={{
                            color: "#717171",
                            width: "25px",
                            height: "auto",
                          }}
                        />
                        <span>Administration</span>
                      </div>
                      <div className="nav_dropdown_right_item">
                        <span>
                          <FontAwesomeIcon
                            className="sidebaricon"
                            icon={faCircleChevronDown}
                            style={{
                              color: "#717171",
                              width: "15px",
                              height: "auto",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </button>
                  <ul className={`dropdown-menu`}>
                    <li>
                      <Link
                        to={"/Homepage/ClientSearch"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                      >
                        Clients
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Homepage/PatientSearch"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                      >
                        Patients
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Homepage/SearchSuppliers"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                      >
                        Suppliers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Homepage/SearchStaff"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                      >
                        Staff
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Patients Dropdown */}
              <li
                className={`nav-item ${
                  isCollapsed ? "mx-auto" : ""
                } ${isDropdownActive([
                  "/Homepage/Appointments",
                  "/Homepage/SearchDiagnosis",
                ])}`}
              >
                <div
                  className={`sideNavDropDown ${
                    isCollapsed === true ? "drop-end" : ""
                  }`}
                >
                  <button
                    type="button"
                    className="sideNavDropDown text-start dropdown-button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-toggle-tooltip="tooltip"
                    title="Patients"
                  >
                    <div className="nav-link p-2 text-black d-flex align-items-center justify-content-between">
                      <div className="nav_dropdown_left_items d-flex align-items-center">
                        <FontAwesomeIcon
                          className="sidebaricon"
                          icon={faStethoscope}
                          style={{
                            color: "#717171",
                            width: "25px",
                            height: "auto",
                          }}
                        />
                        <span>Patients</span>
                      </div>
                      <div className="nav_dropdown_right_item">
                        <span>
                          <FontAwesomeIcon
                            className="sidebaricon"
                            icon={faCircleChevronDown}
                            style={{
                              color: "#717171",
                              width: "15px",
                              height: "auto",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </button>
                  <ul className={`dropdown-menu`}>
                    <li>
                      <Link
                        to={"/Homepage/SearchDiagnosis"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                      >
                        Diagnosis
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Homepage/Appointments"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                      >
                        Appointments
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Finance Dropdown */}
              <li
                className={`nav-item ${
                  isCollapsed ? "mx-auto" : ""
                } ${isDropdownActive([
                  "/Homepage/Vouchers",
                  "/Homepage/SearchReceipts",
                ])}`}
              >
                <div
                  className={`sideNavDropDown ${isCollapsed ? "drop-end" : ""}`}
                >
                  <button
                    type="button"
                    className="sideNavDropDown text-start dropdown-button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-toggle-tooltip="tooltip"
                    title="Finance"
                  >
                    <div className="nav-link p-2 text-black d-flex align-items-center justify-content-between">
                      <div className="nav_dropdown_left_items d-flex align-items-center">
                        <FontAwesomeIcon
                          className="sidebaricon"
                          icon={faMoneyCheckDollar}
                          style={{
                            color: "#717171",
                            width: "25px",
                            height: "auto",
                          }}
                        />
                        <span>Finance</span>
                      </div>
                      <div className="nav_dropdown_right_item">
                        <span>
                          <FontAwesomeIcon
                            className="sidebaricon"
                            icon={faCircleChevronDown}
                            style={{
                              color: "#717171",
                              width: "15px",
                              height: "auto",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </button>
                  <ul className={`dropdown-menu`}>
                    <li>
                      <Link
                        to={"/Homepage/SearchReceipts"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                      >
                        Receipts
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/Homepage/SearchVouchers"}
                        className={`p-2 text-black dropdown-item ps-3 dropdown1 sidebarLink`}
                        style={{ textDecorationLine: "none" }}
                        onClick={() => setIsCollapsed(true)}
                        >
                        Vouchers
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li
                className={`nav-item ${isCollapsed ? "mx-auto" : ""} ${isActive(
                  "/HomePage/Inventory"
                )}`}
              >
                <Link
                  to={"/HomePage/Inventory"}
                  className={`nav-link p-2 text-black d-flex align-items-center sidebarLink`}
                  data-bs-toggle-tooltip="tooltip"
                  title="Inventory"
                  onClick={() => setIsCollapsed(true)}
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faBoxOpen}
                    style={{ color: "#717171", width: "25px", height: "auto" }}
                  />
                  <span>Inventory</span>
                </Link>
              </li>

              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <Link
                  to={"/HomePage/Dashboard"}
                  className={`nav-link p-2 text-black d-flex align-items-center sidebarLink`}
                  data-bs-toggle-tooltip="tooltip"
                  title="Reports"
                  onClick={() => setIsCollapsed(true)}
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faPaste}
                    style={{ color: "#717171", width: "25px", height: "auto" }}
                  />
                  <span>Reports</span>
                </Link>
              </li>

              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <Link
                  to={"/Homepage/Dashboard"}
                  className={`nav-link p-2 text-black d-flex align-items-center sidebarLink`}
                  data-bs-toggle-tooltip="tooltip"
                  title="Utilites"
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faGears}
                    style={{ color: "#717171", width: "25px", height: "auto" }}
                  />
                  <span>Utilities</span>
                </Link>
              </li>

              <li className="nav-item">
                <FontAwesomeIcon
                  className="sidebaricon btnSidebarClose mx-3"
                  icon={faClose}
                  style={{
                    color: "#717171",
                    width: "25px",
                    height: "auto",
                    cursor: "pointer",
                  }}
                  onClick={toggleSidebar}
                />
              </li>
            </ul>
          </div>
          <div
            className="pillp-container text-center mb-3"
            style={{ opacity: "50%" }}
          >
            <img
              src={pillp}
              className="pillp-logo"
              alt="pillp logo"
              style={{ width: "auto", height: "40px" }}
              data-bs-toggle-tooltip="tooltip"
              title="pillp"
            />
            <p style={{ fontSize: "12px" }}>V 1.0.0</p>
            <div className="copyright-info d-flex align-items-center flex-row justify-content-center">
              <FontAwesomeIcon
                className="sidebaricon"
                icon={faCopyright}
                style={{ color: "#717171", width: "12px", height: "auto" }}
              />
              <p style={{ fontSize: "10px" }}>2024-25</p>
            </div>

            {isCollapsed ? (
              ""
            ) : (
              <div className="session-info d-flex flex-column">
                <p style={{ fontSize: "10px" }}>Current Login</p>
                <p style={{ fontSize: "10px" }}>Last Login</p>
              </div>
            )}
          </div>
        </div>

        {/*Header*/}
        <div
          className="flex-grow-1 d-flex flex-column mainContent"
          id="mainContent"
        >
          <nav className="navbar bg-body-tertiary d-flex flex-row justify-content-between align-items-center sticky-top">
            <div className="div1 d-flex align-items-center">
              <img
                src={optolinqlogo}
                className="logo2-navbar"
                alt="logo2"
                style={{ width: "auto", height: "40px" }}
              />
              <FontAwesomeIcon
                icon={faBars}
                onClick={toggleSidebar}
                className="menu mr-3 space-right2 cursor-pointer"
                style={{ color: "#4CC8F7", width: "20px", height: "auto" }}
                data-bs-toggle-tooltip="tooltip"
                title="Menu"
              />

              <nav
                className="d-flex align-items-center"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb d-flex align-items-center">
                  {pathnames.map((value, index) => {
                    let to = `/${pathnames.slice(0, index + 1).join("/")}`;
                    if (value.toLowerCase() === "homepage") {
                      to = "/HomePage/Dashboard";
                    }

                    return (
                      <li key={to} className="breadcrumb-item">
                        <Link to={to}>
                          {value.toLowerCase() === "homepage"
                            ? "Home"
                            : breadcrumbNames[value]}
                        </Link>
                      </li>
                    );
                  })}
                </ol>
              </nav>
            </div>

            <div className="div2 d-flex align-items-center justify-content-between ">
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faBell}
                style={{ color: "#979797", width: "20px", height: "auto" }}
                data-bs-toggle-tooltip="tooltip"
                title="Notifications"
              />
              <Link to={"/HomePage/MyProfile"}>            
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faUserCircle}
                style={{ color: "#979797", width: "20px", height: "auto" }}
                data-bs-toggle-tooltip="tooltip"
                title="Profile"
              />
              </Link>
              <Link to={"/HomePage/finchat"}>
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faHeadset}
                style={{ color: "#979797", width: "20px", height: "auto" }}
                data-bs-toggle-tooltip="tooltip"
                title="Help Desk"
              />
              </Link>
              <Link to={"/"}>    
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faArrowRightFromBracket}
                style={{ color: "#979797", width: "20px", height: "auto" }}
                data-bs-toggle-tooltip="tooltip"
                title="LogOut"
              />
              </Link>
            </div>
          </nav>
          <div className="innerContent flex-grow-1">
            {/* Breadcrumb */}

            {/* Main Content */}
            <div className="formCard flex-grow-1 ">
              <Routes>
                {Homepageroutes.map((route, idx) => {
                  const Component = route.component; // Store component in a variable
                  return (
                    <Route
                      key={idx} // It's important to give each route a unique key
                      path={route.path}
                      element={<Component />} // Render component as JSX
                    />
                  );
                })}
              </Routes>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
